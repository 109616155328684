/* PopupComponent.css */
.popup-contents {
  padding: 12px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.popup-contents h2 {
  color: #ff0000;
  margin-top: 0;
  margin-bottom: 10px;
}

.popup-contents p {
  margin: 0;
  font-size: 16px;
}

.popup-contents button {
  margin-top: 15px;
  padding: 8px 16px;
  border: none;
  background-color: #ff0000;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

.popup-contents button:hover {
  background-color: #ff6347;
}




/* Popup.css */
.sessionExpiredPP-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999 !important;
}

.sessionExpiredPP-content {
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  max-width: fit-content;
  text-align: center;
  position: absolute !important;
  z-index: 9999999 !important;
}

/* .popup-content h2 {
  margin-top: 0;
} */

/* .popup-content button {
  margin-top: 16px;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
} */