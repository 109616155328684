.full_imge {
    width: 100%
}

.img-center {
    margin: auto;
    display: block;
}

.imgabs_right {
    position: absolute;
    right: 10%;
    bottom: 2%;
}

.img-fluid.download_icon{
    margin-left: 20px;
}

.img-fluid.view_icon{
    margin-left: 20px;
}


@media all and (max-width: 767px) {
    .img-fluid.download_icon{
        margin-left: 0px !important;
        margin-top: 10px;
    }
    
    .img-fluid.view_icon{
        margin-left: 0px !important;
        margin-top: 10px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .imgabs_right {
        position: absolute;
        right: 1%;
        bottom: 1%;
    }
}