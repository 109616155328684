.dashboard {
  height: 100%;
}
.dashboard .drawer-content-container {
  display: flex;
  height: 100%;
}
.dashboard svg.optionIcon path {
/*  fill: #fff !important;*/
}
.dashboard svg.optionIcon.selected path {
  fill: #f7c01d !important;
}
