:root {
  --top-for-menu-popup: 100px;
  --primaryBlue: #2B2E8C;
  --hoverButton: #373CD3;
  --textBlue: #182A4E;
  --lightBlue: #6D798E;
  --mediumGrey: #454343;
  --accentBlue: #005DAC;
  --blueGrey: #42526E;
  --flatBlue: #F4F5F7;
  --borderColor: #D3D7DD;
  --borderGrey: #EBECF0;
  --titleWhite: #F0F0FF;
  --footerWhite: #FAFBFC;
  --buttonBorderGrey: #99A1B0;
  --darkGrey: #FFFFFF4D;
  --darkBlack: #191919;
  --borderActive: #005DAC;
  --activeText: #005DAC;
  --flatBlue: #F4F5F7;
  --darkyellow: #FFCF00;
}

/* Only Global css here*/
.padd0 {
  padding: 0px;
}

/*reusable class*/
.color-lightgrey {
  color: #ccc !important;
}

.color-grey {
  color: #a6aab5 !important;
}

.color-green {
  color: #59aa05;
}

.color-red {
  color: #e63946;
}

.color-blue {
  color: #1a2a4e;
}

.color-black {
  color: #212529;
}

.color-lightblue {
  color: #03a9f4 !important;
}

.light-Greenbg {
  background-color: rgba(244, 246, 251, 0.5);
}

.box-shadow-bigbox {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
}

.box-shadow-innerbox {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.box-shadow-top {
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.02);
}

.mo-smalllabel {
  font-size: 16px;
  color: #a6aab5;
  margin-bottom: 0;
}

.navSize {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
}

.absolute_info {
  position: absolute;
}

.borderdotted-bottom-1 {
  border-bottom: 1px dashed #eaecf1;
}

.bg-transperent {
  background-color: transparent !important;
}

.rupeesign {
  font-size: 80%;
  padding-right: 3px;
}

.mar_bottom15 {
  margin-bottom: 15px !important;
}

.mar_bottom10 {
  margin-bottom: 10px !important;
}

.mar_bottom20 {
  margin-bottom: 20px !important;
}

.mar_bottom0 {
  margin-bottom: 0px !important;
}

.mar_bottom25 {
  margin-bottom: 25px !important;
}

.mar_top25 {
  margin-top: 25px !important;
}

.calenderdisabled {
  pointer-events: none !important;
  background: #ccc !important;
}

.pointer {
  pointer-events: none !important;
}

.slider_dots .slick-dots {
  bottom: 10px;
}

.sliderCus .slick-prev,
.slick-next {
  width: 30px !important;
  height: 30px !important;
  background-size: contain !important;
}

.slick-prev:before,
.slick-next:before {
  font-family: none !important;
  content: none !important;
}

.sliderCus .slick-next {
  background: url("../assets/images/arrowleft.png") no-repeat;
}

.sliderCus .slick-next:hover::before,
.sliderCus .slick-next:focus,
.sliderCus .slick-next:hover {
  background: url("../assets/images/arrowleft.png") no-repeat !important;
}

.sliderCus .slick-prev {
  transform: rotate(180deg);
  background: url("../assets/images/arrowleft.png") no-repeat;
}

.sliderCus .slick-prev:hover::before,
.sliderCus .slick-prev:focus,
.sliderCus .slick-prev:hover {
  transform: rotate(180deg);
  background: url("../assets/images/arrowleft.png") no-repeat !important;
}

/* .slick-track,
.slick-slide {
    width: 100% !important;
} */

/*  */
.box_shadow {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  /* box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.8); */
}

/* table */

.table_nav tr th,
.table_nav tr td {
  font-size: 14px;
}

.table_nav tr td,
.table_nav {
  border: 0px !important;
}

.table_nav tr th {
  border-color: #525981 !important;
}

.table_pass tr th {
  background: #272e59;
  padding: 15px 20px;
  color: #fff;

  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
}

.table_pass tr th,
.table_pass tr td {
  padding: 15px 10px !important;
  border-top: 0px solid #fff;
}

.table_pass tr td {
  color: #1a294e;
  font-size: 15px;
  font-family: 'Roboto';
  font-weight: 400;
}

.radbo-left {
  border-top-left-radius: 8px;
}

.radbo-right {
  border-top-right-radius: 8px;
}

.bg_coltr1 {
  background: #eaeaf6;
}

.sliderCus .slick-list {
  width: 100% !important;
}

.information-button {
  font-size: 14px;
  color: #fb4d4d;
}

.information-button img {
  max-width: auto !important;
  display: inline-block !important;
}

.information-button:hover {
  color: #23527c;
  text-decoration: underline;
}

.bodycolor_faq {
  background: #f4f6fb;
  min-height: 100vh;
}

.playicon_btn {
  width: 80px;
  height: 80px;
  background: url("../assets/images/landing/playicon.svg");
  background-size: contain;
  margin: 20px auto;
  display: block;
  cursor: pointer;
}

.play_btnabs {
  width: 46px;
  height: 46px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.color_white {
  color: #fff !important;
}

.col_overflowpdf {
  overflow: scroll;
  width: 100%;
}

.Readmorebtn {
  cursor: pointer;
}

/* .MuiListItem-root{
    color:#7B869C !important;
} */
.open_tool .__react_component_tooltip {
  visibility: visible;
  opacity: 1;
}

.oneline_text {
  overflow: hidden;
  white-space: nowrap;
}

.break_word {
  word-break: break-word;
}

.numberMO_library .react-tel-input .form-control {
  width: 100% !important;
  height: 38px;
}

.disabled_link {
  pointer-events: none !important;
  cursor: default !important;
}

@media (min-width: 320px) and (max-width: 767px) {
  .page-link {
    padding: 0.3rem 0.5rem !important;
  }

  /* global */
  .mobilepadd0 {
    padding: 0px !important;
  }

  .slick-prev {
    left: 0px !important;
  }

  .slick-next {
    right: 0px !important;
  }

  .sliderCus .slick-prev,
  .slick-next {
    z-index: 99 !important;
  }

  .absolute_info {
    position: relative;
  }

  .navSize {
    font-size: 11px;
  }
}



html .primary-blue-button {
  border: none;
  font-size: 14px; 
  padding: 0.5rem 1.35rem;
  background-color: var(--primaryBlue);
  border: 1px var(--borderColor) solid;
  color: #FFF;
  border-radius: 8px;
  text-align: center; 
  cursor: pointer;
}

html .primary-blue-button:hover {
  background-color: var(--hoverButton); 
  color: #FFF
}
