@import "../../node_modules/bootstrap/dist/css/bootstrap.css";
@import "../assets/css/fonts.css";
@import url("./tailwind.header.css");
/* Normalize css here*/
/* Dont't add Component and page css here only Normalize css allowed */

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; 
  font-family: "Titillium Web", sans-serif !important;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: initial;
}

.img-responsive {
  max-width: 100%;
}

.padd0 {
  padding: 0px !important;
}

.bgWhite {
  background: #fff;
}

input {
  border: none;
  padding: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid silver;
}

input:disabled {
  background-color: unset;
}

*:focus {
  outline: unset;
}

/* login page css */

.investBG {
  width: 100%;
  min-height: 770px;
  position: relative;
  padding-top: 8px;
  /* overflow: hidden; */
  box-sizing: border-box;
}

.investBG1::after {
  content: "";
  width: 41%;
  background: url("../assets/images/leftbg.jpg") no-repeat;
  min-height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-size: cover;
}

.investBG1::before {
  content: "";
  width: 59%;
  background: url("../assets/images/rightbg.svg") no-repeat;
  min-height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-size: cover;
}

.innerinvest {
  position: relative;
  z-index: 10;
  margin: 50px auto;
}

.bg_shadow {
  width: 100%;
  min-height: 600px;
  background: #f4f6fb;
  box-sizing: border-box;
  border: 10px solid rgba(255, 255, 255, 0.5);
}

.colCon {
  height: 100%;
}

.loginSec {
  padding: 50px 20px;
}

.loginTab {
  margin: 0px;
  padding: 0px;
  border-bottom: 3px solid #e6e6e6;
}

.loginTab li {
  display: inline-block;
  font-size: 16px;
  color: #6a738a;
  font-family: 'Roboto';
font-weight: 400;
  width: 160px;
  text-align: center;
  padding: 20px 0px;
  position: relative;
  cursor: pointer;
  bottom: -2px;
  border-bottom: 3px solid transparent;
}

.loginTab li a {
  font-size: 16px;
  color: #6a738a;
  font-family: 'Roboto';
font-weight: 400;
  text-decoration: none;
}

.loginTab li.active {
  color: #faa528;
  border-color: #faa528;
}

.img-Right {
  padding: 0px;
}

.img-Right .imgRight {
  float: right;
}

.loginmar30 {
  margin: 30px 0px;
}

.loginTitle {
  color: #323b6d;
  font-family: 'Roboto';
font-weight: 700;
  font-size: 20px;
  margin-bottom: 20px;
}

.paddLeftlogin {
  padding-left: 0px;
}

.noBg {
  background-color: transparent;
}

.getOTP {
  border: 1px solid #fb4d4d;
  color: #fb4d4d;
  font-size: 13px;
  font-family: 'Roboto';
font-weight: 400;
  border-radius: 70px;
  padding: 5px 15px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 85px;
}

.forpwd {
  color: #323b6d;
  font-size: 14px;
  font-family: 'Roboto';
font-weight: 500;
  cursor: pointer;
  display: inline-block;
}

.forpwd span {
  display: inline-block;
  width: 23px;
  height: 23px;
  background-color: #323b6d;
  border-radius: 50px;
  vertical-align: middle;
  margin-right: 6px;
}

.forpwd span img {
  margin: 0px auto;
  display: block;
  padding-top: 6px;
}

.formMargin {
  margin-bottom: 30px;
  min-height: 64px;
}

.lbl {
  color: #6a738a;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Roboto';
font-weight: 400;
}

.formRe {
  position: relative;
  width: 100%;
}

.txtbox {
  width: 100%;
  height: 38px;
  border: 0px;
  box-shadow: none;
  border-bottom: 1px solid #bfc3ce;
  font-size: 14px;
  color: #323b6d;
  padding-left: 0px;
  font-family: 'Roboto';
font-weight: 400;
}

.txtbox:hover,
.txtbox:focus {
  outline: none;

  box-shadow: none;
}

.formMargin {
  margin-bottom: 30px;
  min-height: 64px;
}

.invest_btn {
  background: #fb4d4d;
  font-size: 16px;
  border-radius: 20px;
  border: 0px;
  color: #fff;
  font-family: 'Roboto';
font-weight: 400;
  padding: 8px 15px;

  margin-top: -10px;
  outline: none;
}

.sunbtn {
  margin-top: 0px;
  width: auto;
  min-width: 130px;
  max-width: none;
  padding: 8px 23px;
  border-radius: 4px;
}

.marbtntop {
  margin-top: 25%;
}

.btnGro {
  margin: 15px 0px;
}

.greenInfo {
  color: #323b6d;
  font-size: 14px;
  font-family: 'Roboto';
font-weight: 500;
  margin: 0px;
}

.greenInfo span {
  display: inline-block;
  vertical-align: middle;
  width: 35px;
  height: 35px;
}
