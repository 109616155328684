.footerRevamp {
  * {
    font-family: Work Sans, sans-serif;
    box-sizing: border-box;
    background-color: #2B2E8C;
  }

  img {
    display: block;
  }

  .footer-wrapper-column-template {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .links-column-template {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  @media (min-width: 768px) {
    .footer-bg {
      right: 0;
      top: 134px
    }

    .footer-wrapper-column-template {
      grid-template-columns: 272px 1fr
    }

    .links-column-template {
      grid-template-columns: repeat(auto-fill, 272px);
    }
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1172px;
      padding: 0;
    }
  }

  @media (max-width: 1367px) {
    .footer-bg {
      right: 0px;
    }
  }

  @media (min-width: 1680px) {
    .container {
      max-width: 1486px;
      padding: 0;
      overflow: hidden;
    }

    .footer-bg {
      top: 118px;
    }

    .footer-wrapper-column-template {
      grid-template-columns: 351px 1fr;
      column-gap: 26px;
    }

    .links-column-template {
      grid-template-columns: repeat(auto-fill, 351px);
    }
  }

  @media (min-width: 1920px) {
    .footer-bg {
      top: 118px;
    }
  }
}