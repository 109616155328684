.container-scroller {
  overflow: hidden;
}

.logo-col-container {
  flex-direction: column;
}

.logo-row-container {
  flex-direction: row;
}

/* nav bar */
/* Navbar */
.container-scroller .navbar {
  font-family: 'Roboto';
  font-weight: 400;
  background: #fff;
  position: fixed;
  transition: background 0.25s ease;
  -webkit-transition: background 0.25s ease;
  -moz-transition: background 0.25s ease;
  -ms-transition: background 0.25s ease;
  z-index: 500;
  width: auto;
  margin: 10px;
  border-radius: 12px;
  position: sticky;
  top: 0;
}

.navbar .navbar-brand {
  width: 110px;
}

.navbar-brand.brand-logo-mini{
  flex-shrink: 1;
}

.nav-profile-text {
  font-size: 16px;
}

.navbar .navbar-brand-wrapper {
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease;
  background: #fff;
  width: 258px;
  height: 64px;
}

@media (max-width: 991px) {
  .navbar .navbar-brand-wrapper {
    width: 55px;
  }
}

.navbar .navbar-brand-wrapper .navbar-brand {
  color: #27367f;
  font-size: 1.5rem;
  line-height: 48px;
  margin-right: 0;
  padding: 0.25rem 0;
  width: 100%;
}



.navbar .navbar-brand-wrapper .navbar-brand:active,
.navbar .navbar-brand-wrapper .navbar-brand:focus,
.navbar .navbar-brand-wrapper .navbar-brand:hover {
  color: #1b2658;
}

.navbar .navbar-brand-wrapper .navbar-brand img {
  /* width: auto; */
  max-width: 100%;
  /* height: 60px; */
  margin: auto;
  vertical-align: middle;
  width: 129px;
  height: 50px;
}

.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
  display: none;
}

.navbar-toggler {
  padding: 2px;
}

.navbar-brand-wrapper .navbar-brand {
  margin-right: 10px;
  margin-left: 10px;
}


/* .navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
  width: 46;
  max-width: 100%;
  height: 46px;
  margin: auto;
} */

.container-scroller .main-panel.show-full-screen {
  margin-left: 0px;
  width: 100%;
  position: static;
}

.navbar .navbar-menu-wrapper {
  transition: width 0.25s ease;
  -webkit-transition: width 0.25s ease;
  -moz-transition: width 0.25s ease;
  -ms-transition: width 0.25s ease;
  color: #111111;
  /* padding-left: 20px;
  padding-right: 20px; */
  width: 100%;
  height: auto;
}

@media (max-width: 991px) {
  .navbar .navbar-menu-wrapper {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.navbar .navbar-menu-wrapper .navbar-toggler {
  border: 0;
  color: #444;
  height: 64px;
  border-radius: 0px;
  padding-left: 5px;
  padding-right: 20px;
}

.navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
  font-size: 24px;
}

.sidebar-icon-only .navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) .mdi-menu:before {
  content: "\F35C";
}

.navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) .mdi-menu:before {
  content: "\F04D";
}

@media (max-width: 991px) {
  .navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
    display: none;
  }
}

@media (max-width: 991px) {
  .navbar .navbar-menu-wrapper .navbar-toggler.navbar-toggler-right {
    padding-left: 15px;
    padding-right: 11px;
    border-right: none;
  }
}

.navbar .navbar-menu-wrapper .search-field .input-group {
  background: #eef0fa;
  width: 305px;
}

.navbar .navbar-menu-wrapper .search-field .input-group input {
  font-size: 0.875rem;
  padding: 0.5rem;
}

.navbar .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
  font-size: 0.875rem;
  color: #111111;
  font-family: 'Roboto';
  font-weight: 400;
}

.navbar .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
  font-size: 0.875rem;
  color: #111111;
  font-family: 'Roboto';
  font-weight: 400;
}

.navbar .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
  font-size: 0.875rem;
  color: #111111;
  font-family: 'Roboto';
  font-weight: 400;
}

.navbar .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
  font-size: 0.875rem;
  color: #111111;
  font-family: 'Roboto';
  font-weight: 400;
}

.navbar .navbar-menu-wrapper .search-field .input-group i {
  font-size: 14px;
  margin-right: 0;
  color: #8e94a9;
}

.navbar .navbar-menu-wrapper .search-field .input-group .input-group-text {
  background: transparent;
}

.nav-logo {
  display: none;
}

.navbar .navbar-menu-wrapper .badge.badge-up {
  position: absolute;
  top: 12px;
  /* min-width: 10px; */
  /* min-height: 10px; */
  display: flex;
  align-items: center;
  height: 20px;
  padding: 0;
  width: 20px;
  justify-content: center;
  font-size: 12px;
  line-height: 9px;
  right: -9px;
  background-color: #ed1c24;
  color: #fff;
  font-family: 'Roboto';
  font-weight: 700;
}

.navbar .navbar-menu-wrapper .count-indicator {
  position: relative;
}

.navbar .navbar-menu-wrapper .count-indicator .count-symbol,
.navbar .navbar-menu-wrapper .count-indicator .count-number {
  position: absolute;
  border-radius: 100%;
  border: 2px solid #ffffff;
  -webkit-animation: pulse 3s ease-out;
  animation: pulse 3s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  40% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
  }

  55% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  60% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
  }

  65% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.navbar .navbar-menu-wrapper .count-indicator .count-symbol {
  top: 20px;
  right: 0px;
  width: 10px;
  height: 10px;
}

.navbar .navbar-menu-wrapper .count-indicator .count-number {
  min-width: 14px;
  height: 14px;
  font-size: 0.5rem;
  color: #ffffff;
  bottom: 16px;
  right: -5px;
  line-height: 1;
  text-align: center;
}

.navbar .navbar-menu-wrapper .count-indicator:after {
  display: none;
}

.navbar .navbar-menu-wrapper .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  /* -ms-flex-direction: row; */
  flex-direction: row;
  /* -webkit-box-align: center;
  -ms-flex-align: center; */
  /* align-items: center; */
}

.navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-left li {
  margin-right: 10px;
  padding: 15px;
}

.navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-left li a:hover {
  color: #ffffff;
}

.navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-left li:hover {
  background-color: #E19C14;
  text-decoration: none;
  color: #ffffff;
  size: 10px;
  cursor: pointer;
}

.navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-left .active {
  background-color: #E19C14;
  text-decoration: none;
  color: #ffffff;
  size: 10px;
}

.navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-left li {
  margin-right: 10px;
  padding: 15px;
}

.navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-left li a:hover {
  color: #ffffff;
}

.navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-left li:hover {
  background-color: #E19C14;
  text-decoration: none;
  color: #ffffff;
  size: 10px;
  cursor: pointer;
}

.navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-left .active {
  background-color: #E19C14;
  text-decoration: none;
  color: #ffffff;
  size: 10px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
  color: inherit;
  font-size: 0.875rem;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  height: 64px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 767px) {
  .navbar .navbar-menu-wrapper .navbar-nav {
    font-size: 12px;
  }
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link i {
  font-size: 28px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link .manual_headericon {
  width: 32px;
  height: 32px;
  display: inline-block;
  background: url("../../../assets/images/nav/manual-icon.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  margin-right: 5px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link .feedback_headericon {
  width: 32px;
  height: 32px;
  display: inline-block;
  background: url("../../../assets/images/nav/feedback-icon.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  margin-right: 5px;
}

.sidebar .nav .nav-item .nav-link .icon-bg .manual_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/manual-icon-light.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav .nav-item .nav-link .icon-bg .feedback_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/feedback-icon-light.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav .nav-item .nav-link .icon-bg .manual_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/manual-icon-light.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav .nav-item .nav-link .icon-bg .feedback_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/feedback-icon-light.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav:not(.sub-menu)>.nav-item:hover:not(.nav-category):not(.nav-profile)>.nav-link .icon-bg .manual_navicon,
.sidebar .nav .nav-item.open .nav-link .icon-bg .manual_navicon,
.sidebar .nav .nav-item.active .nav-link .icon-bg .manual_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/manual-icon.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav:not(.sub-menu)>.nav-item:hover:not(.nav-category):not(.nav-profile)>.nav-link .icon-bg .feedback_navicon,
.sidebar .nav .nav-item.open .nav-link .icon-bg .feedback_navicon,
.sidebar .nav .nav-item.active .nav-link .icon-bg .feedback_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/feedback-icon.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link .mastercart_headericon {
  width: 32px;
  height: 32px;
  display: inline-block;
  background: url("../../../assets/images/nav/mastercart-icon.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  margin-right: 10px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-toggle:after {
  color: #444;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 0;
  font: normal normal normal 24px/1 "Material Design Icons";
  content: "\f140";
  width: auto;
  height: auto;
  vertical-align: baseline;
  font-size: 14px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
  border: none;
  -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
  min-width: 220px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown {
  position: absolute;
  font-size: 0.9rem;
  margin-top: 0;
  padding: 0;
}

.rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown {
  right: auto;
  left: 0;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown h5.dropdown-header {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 12px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item {
  margin-bottom: 0;
  padding: 11px 10px;
  cursor: pointer;
  color: #111111;
  font-size: 0.875rem;
  font-style: normal;
  font-family: 'Roboto';
  font-weight: 400;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item:active {
  background-color: #d1d6dc;
  text-decoration: none;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item .badge {
  padding: 2px 6px;
  font-size: 10px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item i {
  font-size: 17px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item .ellipsis {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item i {
  margin-left: 5px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-divider {
  margin: 0;
}

.preview-list .preview-item .preview-thumbnail {
  color: #ffffff;
  position: relative;
}

.preview-list .preview-item .preview-thumbnail img,
.preview-list .preview-item .preview-thumbnail .preview-icon {
  width: 36px;
  height: 36px;
  border-radius: 100%;
}

.preview-list .preview-item .preview-item-content {
  line-height: 1;
  padding-left: 15px;
}

@media (max-width: 991px) {
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown {
    position: static;
  }

  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
    left: 20px;
    right: 20px;
    top: 64px;
    width: calc(100% - 40px);
  }
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings {
  padding-left: 20px;
  padding-right: 5px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings .nav-link {
  margin-right: 0;
  margin-left: 0;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-img {
  position: relative;
  width: 32px;
  height: 32px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-img img {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-text {
  margin-left: 0.875rem;
}

.rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-text {
  margin-left: 0;
  margin-right: 0.875rem;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-text p {
  line-height: 1;
}

@media (max-width: 767px) {

  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-text,
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link .cart-text {
    display: none;
  }

  .navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link .mastercart_headericon {
    margin-right: 0;
  }
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link.dropdown-toggle:after {
  line-height: 2;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-language .nav-link .nav-language-icon {
  position: relative;
  width: 22px;
  height: 28px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-language .nav-link .nav-language-icon i {
  font-size: 1rem;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-language .nav-link .nav-language-text {
  margin-left: 0.875rem;
}

.rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-language .nav-link .nav-language-text {
  margin-left: 0;
  margin-right: 0.875rem;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-language .nav-link .nav-language-text p {
  line-height: 1;
}

@media (max-width: 767px) {
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-language .nav-link .nav-language-text {
    display: none;
  }
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-language .nav-link.dropdown-toggle:after {
  line-height: 2;
}

@media (min-width: 992px) {
  .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
    margin-left: auto;
  }

  .rtl .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
    margin-left: 0;
    margin-right: auto;
  }
}

@media (max-width: 991px) {
  .navbar {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar .navbar-brand-wrapper {
    width: 140px;
    height: 100%;
  }

  /* .navbar .navbar-brand-wrapper .navbar-brand.brand-logo {
        display: none; }
      .navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
        display: inline-block; } */
  .navbar .navbar-brand-wrapper .navbar-brand img {
    /* width: auto; */
    max-width: 100%;
    /* height: 60px; */
    margin: auto;
    vertical-align: middle;
    width: 129px;
    height: 50px;
  }

  .nav-logo {
    display: block;
  }

  .navbar-collapse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0.5rem;
  }
}

@media (max-width: 480px) {
  .navbar .navbar-brand-wrapper {
    /* width: 55px;  */
  }

  .navbar .navbar-brand-wrapper .brand-logo-mini {
    padding-top: 0px;
  }
}

/* Navbar color variations */
.navbar.navbar-primary .navbar-menu-wrapper {
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(#da8cff),
      to(#9a55ff));
  background: linear-gradient(to right, #da8cff, #9a55ff);
  color: #ffffff;
}

.navbar.navbar-primary .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-primary .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
  color: #ffffff;
}

.navbar.navbar-primary .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
  color: #ffffff;
}

.navbar.navbar-primary .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-primary .navbar-menu-wrapper .search-field .input-group i {
  color: #ffffff;
}

.navbar.navbar-secondary .navbar-menu-wrapper {
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(#e7ebf0),
      to(#868e96));
  background: linear-gradient(to right, #e7ebf0, #868e96);
  color: #ffffff;
}

.navbar.navbar-secondary .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-secondary .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
  color: #ffffff;
}

.navbar.navbar-secondary .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
  color: #ffffff;
}

.navbar.navbar-secondary .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-secondary .navbar-menu-wrapper .search-field .input-group i {
  color: #ffffff;
}

.navbar.navbar-success .navbar-menu-wrapper {
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(#84d9d2),
      to(#07cdae));
  background: linear-gradient(to right, #84d9d2, #07cdae);
  color: #ffffff;
}

.navbar.navbar-success .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-success .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
  color: #ffffff;
}

.navbar.navbar-success .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
  color: #ffffff;
}

.navbar.navbar-success .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-success .navbar-menu-wrapper .search-field .input-group i {
  color: #ffffff;
}

.navbar.navbar-info .navbar-menu-wrapper {
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(#90caf9),
      color-stop(99%, #047edf));
  background: linear-gradient(to right, #90caf9, #047edf 99%);
  color: #ffffff;
}

.navbar.navbar-info .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-info .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
  color: #ffffff;
}

.navbar.navbar-info .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
  color: #ffffff;
}

.navbar.navbar-info .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-info .navbar-menu-wrapper .search-field .input-group i {
  color: #ffffff;
}

.navbar.navbar-warning .navbar-menu-wrapper {
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(#f6e384),
      to(#ffd500));
  background: linear-gradient(to right, #f6e384, #ffd500);
  color: #ffffff;
}

.navbar.navbar-warning .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-warning .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
  color: #ffffff;
}

.navbar.navbar-warning .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
  color: #ffffff;
}

.navbar.navbar-warning .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-warning .navbar-menu-wrapper .search-field .input-group i {
  color: #ffffff;
}

.navbar.navbar-danger .navbar-menu-wrapper {
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(#ffbf96),
      to(#fe7096));
  background: linear-gradient(to right, #ffbf96, #fe7096);
  color: #ffffff;
}

.navbar.navbar-danger .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-danger .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
  color: #ffffff;
}

.navbar.navbar-danger .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
  color: #ffffff;
}

.navbar.navbar-danger .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-danger .navbar-menu-wrapper .search-field .input-group i {
  color: #ffffff;
}

.navbar.navbar-light .navbar-menu-wrapper {
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      from(#f4f4f4),
      to(#e4e4e9));
  background: linear-gradient(to bottom, #f4f4f4, #e4e4e9);
  color: #ffffff;
}

.navbar.navbar-light .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-light .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
  color: #ffffff;
}

.navbar.navbar-light .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
  color: #ffffff;
}

.navbar.navbar-light .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-light .navbar-menu-wrapper .search-field .input-group i {
  color: #ffffff;
}

.navbar.navbar-dark .navbar-menu-wrapper {
  background: linear-gradient(89deg, #5e7188, #3e4b5b);
  color: #ffffff;
}

.navbar.navbar-dark .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-dark .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
  color: #ffffff;
}

.navbar.navbar-dark .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
  color: #ffffff;
}

.navbar.navbar-dark .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-dark .navbar-menu-wrapper .search-field .input-group i {
  color: #ffffff;
}

/* nav bar */

/* sidebar */
/* Sidebar */
.sidebar {
  min-height: calc(100vh - 64px);
  background: #fff;
  font-family: 'Roboto';
  font-weight: 400;
  padding: 0 10px 0 10px;
  width: 256px;
  z-index: 550;
  position: fixed;
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease;
  -webkit-box-shadow: 0 0 15px 0 rgba(34, 41, 47, 0.15);
  box-shadow: 0 0 15px 0 rgba(34, 41, 47, 0.15);
}

.sidebar .nav {
  /* overflow: hidden; */
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 10px 0 50px;
  overflow-y: auto;
  height: calc(100vh - 85px);
  /* padding: 0 15px 0 10px; */
}

/* total width */
.sidebar .nav::-webkit-scrollbar {
  background-color: #fff;
  width: 16px
}

/* background of the scrollbar except button or resizer */
.sidebar .nav::-webkit-scrollbar-track {
  background-color: #fff
}

.sidebar .nav::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4
}

/* scrollbar itself */
.sidebar .nav::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff
}

.sidebar .nav::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */
.sidebar .nav::-webkit-scrollbar-button {
  display: none
}


.sidebar .nav .nav-item {
  padding: 0 0px;
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
  transition-property: background;
  -webkit-transition-property: background;
  position: relative;
  border-radius: 0.25rem;
  margin: 2px 0px;
}

.sidebar .nav .nav-item .collapse {
  z-index: 999;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.sidebar .nav .nav-item .nav-link {
  min-width: 61px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* white-space: nowrap; */
  padding: 13px 8px 13px 13px;
  color: #7b869c;
  -webkit-transition-duration: 0.45s;
  transition-duration: 0.45s;
  transition-property: color;
  -webkit-transition-property: color;
  text-overflow: ellipsis;
  border-radius: 0.25rem;
}

.sidebar .nav .nav-item .nav-link .icon-bg .myportfolio_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/myportfolio_navicon-light.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav .nav-item .nav-link .icon-bg .newFolio_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/dashboard-new-folio-icon-light.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav:not(.sub-menu)>.nav-item:hover:not(.nav-category):not(.nav-profile)>.nav-link .icon-bg .newFolio_navicon,
.sidebar .nav .nav-item.active .nav-link .icon-bg .newFolio_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/dashboard-new-folio-icon.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav:not(.sub-menu)>.nav-item:hover:not(.nav-category):not(.nav-profile)>.nav-link .icon-bg .bulkTxn_navicon,
.sidebar .nav .nav-item.active .nav-link .icon-bg .bulkTxn_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/dashboard-bulk-transaction-icon.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav .nav-item .nav-link .icon-bg .bulkTxn_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/dashboard-bulk-transaction-icon-light.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav:not(.sub-menu)>.nav-item:hover:not(.nav-category):not(.nav-profile)>.nav-link .icon-bg .quickInvest_navicon,
.sidebar .nav .nav-item.active .nav-link .icon-bg .quickInvest_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/dashboard-quick-invest-icon.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav .nav-item .nav-link .icon-bg .quickInvest_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/dashboard-quick-invest-icon-light.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav:not(.sub-menu)>.nav-item:hover:not(.nav-category):not(.nav-profile)>.nav-link .icon-bg .myportfolio_navicon,
.sidebar .nav .nav-item.active .nav-link .icon-bg .myportfolio_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/myportfolio_navicon.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav .nav-item .nav-link .icon-bg .invest_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/dashboard-investor-list-icon-light.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav:not(.sub-menu)>.nav-item:hover:not(.nav-category):not(.nav-profile)>.nav-link .icon-bg .invest_navicon,
.sidebar .nav .nav-item.open .nav-link .icon-bg .invest_navicon,
.sidebar .nav .nav-item.active .nav-link .icon-bg .invest_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/dashboard-investor-list-icon.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav .nav-item .nav-link .icon-bg .aldemat_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/aldemat_navicon-light.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav:not(.sub-menu)>.nav-item:hover:not(.nav-category):not(.nav-profile)>.nav-link .icon-bg .aldemat_navicon,
.sidebar .nav .nav-item.active .nav-link .icon-bg .aldemat_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/aldemat_navicon.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav .nav-item .nav-link .icon-bg .goalbase_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/goalbase_navicon-light.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav:not(.sub-menu)>.nav-item:hover:not(.nav-category):not(.nav-profile)>.nav-link .icon-bg .goalbase_navicon,
.sidebar .nav .nav-item.active .nav-link .icon-bg .goalbase_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/goalbase_navicon.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav .nav-item .nav-link .icon-bg .redemption_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/redemption_navicon-light.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav:not(.sub-menu)>.nav-item:hover:not(.nav-category):not(.nav-profile)>.nav-link .icon-bg .redemption_navicon,
.sidebar .nav .nav-item.active .nav-link .icon-bg .redemption_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/redemption_navicon.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav .nav-item .nav-link .icon-bg .transfer_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/transfer_navicon-light.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav:not(.sub-menu)>.nav-item:hover:not(.nav-category):not(.nav-profile)>.nav-link .icon-bg .transfer_navicon,
.sidebar .nav .nav-item.active .nav-link .icon-bg .transfer_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/transfer_navicon.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav .nav-item .nav-link .icon-bg .mnschedule_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/mnschedule_navicon-light.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav:not(.sub-menu)>.nav-item:hover:not(.nav-category):not(.nav-profile)>.nav-link .icon-bg .mnschedule_navicon,
.sidebar .nav .nav-item.open .nav-link .icon-bg .mnschedule_navicon,
.sidebar .nav .nav-item.active .nav-link .icon-bg .mnschedule_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/mnschedule_navicon.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav .nav-item .nav-link .icon-bg .smartplan_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/smartplan_navicon-light.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav:not(.sub-menu)>.nav-item:hover:not(.nav-category):not(.nav-profile)>.nav-link .icon-bg .smartplan_navicon,
.sidebar .nav .nav-item.active .nav-link .icon-bg .smartplan_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/smartplan_navicon.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav .nav-item .nav-link .icon-bg .orderbook_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/orderbook_navicon-light.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav:not(.sub-menu)>.nav-item:hover:not(.nav-category):not(.nav-profile)>.nav-link .icon-bg .orderbook_navicon,
.sidebar .nav .nav-item.active .nav-link .icon-bg .orderbook_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/orderbook_navicon.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav .nav-item .nav-link .icon-bg .managepr_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/managepr_navicon-light.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav:not(.sub-menu)>.nav-item:hover:not(.nav-category):not(.nav-profile)>.nav-link .icon-bg .managepr_navicon,
.sidebar .nav .nav-item.active .nav-link .icon-bg .managepr_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/managepr_navicon.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav .nav-item .nav-link .icon-bg .manageac_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/manageac_navicon-light.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav:not(.sub-menu)>.nav-item:hover:not(.nav-category):not(.nav-profile)>.nav-link .icon-bg .manageac_navicon,
.sidebar .nav .nav-item.active .nav-link .icon-bg .manageac_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/manageac_navicon.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav .nav-item .nav-link .icon-bg .report_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/dashboard-report-icon.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav:not(.sub-menu)>.nav-item:hover:not(.nav-category):not(.nav-profile)>.nav-link .icon-bg .report_navicon,
.sidebar .nav .nav-item.open .nav-link .icon-bg .report_navicon,
.sidebar .nav .nav-item.active .nav-link .icon-bg .report_navicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../../../assets/images/nav/report_navicon.svg") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.sidebar .nav .nav-item .nav-link .icon-bg .lumsum_ac {
  width: 30px;
  height: 26px;
  display: inline-block;
  background: url("../../../assets/images/dash/coin-light.svg") no-repeat;
  background-size: cover;
  cursor: pointer;
}

.sidebar .nav:not(.sub-menu)>.nav-item:hover:not(.nav-category):not(.nav-profile)>.nav-link .icon-bg .lumsum_ac {
  width: 30px;
  height: 26px;
  background: url("../../../assets/images/dash/coin.svg") no-repeat;
  background-size: cover;
}

.sidebar .nav .nav-item .nav-link .icon-bg {
  margin-right: 15px;
  background: rgba(194, 244, 219, 0.12);
  height: 20px;
  width: 20px;
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 3px;
  text-align: center;
}

.rtl .sidebar .nav .nav-item .nav-link .icon-bg {
  margin-left: 1rem;
  margin-right: 0;
}

.sidebar .nav .nav-item .nav-link .icon-bg .menu-icon:after,
.sidebar .nav .nav-item .nav-link .icon-bg .menu-icon:before {
  font-size: 1rem;
  color: inherit;
}

.sidebar .nav .nav-item .nav-link i {
  color: inherit;
}

.sidebar .nav .nav-item .nav-link i.menu-icon {
  font-size: 1.125rem;
  line-height: 1;
  margin-right: 0;
  color: #8e94a9;
}

.rtl .sidebar .nav .nav-item .nav-link i.menu-icon {
  margin-left: 0;
  margin-right: 0;
}

.sidebar .nav .nav-item .nav-link i.menu-icon:before {
  vertical-align: middle;
}

.sidebar .nav .nav-item .nav-link i.menu-arrow {
  font: normal normal normal 24px/1 "Material Design Icons";
  line-height: 1;
  font-size: 1.125rem;
  margin-left: auto;
  color: #bfbfbf;
}

.rtl .sidebar .nav .nav-item .nav-link i.menu-arrow {
  margin-left: 0;
  margin-right: auto;
}

.sidebar .nav .nav-item .nav-link i.menu-arrow:before {
  content: "\f140";
  font-size: inherit;
  color: inherit;
}

.sidebar .nav .nav-item .nav-link i.menu-arrow+.menu-icon {
  margin-left: 0.25rem;
}

.rtl .sidebar .nav .nav-item .nav-link i.menu-arrow+.menu-icon {
  margin-left: 0;
  margin-right: 0.25rem;
}

.sidebar .nav .nav-item .nav-link .menu-title {
  color: inherit;
  display: inline-block;
  font-size: 14px;
  line-height: 1;
  vertical-align: middle;
}

.sidebar .nav .nav-item .nav-link .badge {
  margin-right: auto;
  margin-left: 1rem;
}

.sidebar .nav .nav-item .nav-link[aria-expanded="true"] .menu-arrow:before {
  content: "\f140";
}

.sidebar .nav .nav-item.active {
  background: transparent;
}

.sidebar .nav .nav-item.active>.nav-link:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: #25375b;
  height: 46px;
  width: 4px;
}

.rtl .sidebar .nav .nav-item.active>.nav-link:before {
  left: auto;
  right: 0;
}

.sidebar .nav .nav-item.active>.nav-link .menu-title {
  color: #25375b;
}

.sidebar .nav .nav-item.open,
.sidebar .nav .nav-item>a.nav-link:hover {
  background-color: rgba(90, 98, 147, 0.1);
}

.sidebar .nav .nav-item.active>.nav-link {
  background-color: rgba(90, 98, 147, 0.15);
}

.sidebar .nav .nav-item.nav-profile .nav-link {
  height: auto;
  line-height: 1;
  border-top: 0;
  padding: 1.25rem 0;
}

.sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-image {
  width: 44px;
  height: 44px;
}

.sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-image img {
  width: 44px;
  height: 44px;
  border-radius: 100%;
}

.sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-text {
  margin-left: 1rem;
}

.rtl .sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-text {
  margin-left: auto;
  margin-right: 1rem;
}

.sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-badge {
  font-size: 1.125rem;
  margin-left: auto;
}

.rtl .sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-badge {
  margin-left: 0;
  margin-right: auto;
}

.sidebar .nav .nav-item.sidebar-actions {
  margin-top: 1rem;
}

.sidebar .nav .nav-item.sidebar-actions .nav-link {
  border-top: 0;
  display: block;
  height: auto;
}

.sidebar .nav .nav-item.sidebar-actions:hover {
  background: initial;
}

.sidebar .nav .nav-item.sidebar-actions:hover .nav-link {
  color: initial;
}

.sidebar .nav .nav-item.nav-category {
  color: #001737;
  font-size: 0.8125rem;
  line-height: 16px;
  margin: 25px 0px 10px 0px;
  color: #8e94a9;
  font-family: 'Roboto';
  font-weight: 400;
}

.sidebar .nav .nav-item.nav-category .nav-link {
  padding: 1.2rem 2rem 0.2rem;
}

.sidebar .nav .nav-item.nav-category .nav-link:hover {
  color: #001737;
}

.sidebar .nav .nav-item.documentation-link {
  margin: 2.5rem 0 !important;
}

.sidebar .nav .nav-item.documentation-link .nav-link {
  background: #0062ff;
  color: #ffffff;
  border-radius: 2px;
  padding: 0.875rem 0;
  font-size: 1.125rem;
  font-weight: bold;
}

.sidebar .nav .nav-item.documentation-link .nav-link .icon-bg {
  display: none;
}

.sidebar .nav .nav-item.documentation-link .nav-link .menu-title {
  margin: auto;
  background: transparent !important;
  color: #ffffff !important;
}

.sidebar .nav .nav-item.sidebar-user-actions {
  padding-left: 0;
  padding-right: 0;
}

.sidebar .nav .nav-item.sidebar-user-actions .user-details {
  padding: 1rem 2.375rem;
  width: 100%;
  border-top: 1px solid rgba(151, 151, 151, 0.27);
  border-bottom: 1px solid rgba(151, 151, 151, 0.27);
  margin-bottom: 20px;
}

.sidebar .nav .nav-item.sidebar-user-actions .user-details .sidebar-profile-img {
  width: 31px;
  height: 31px;
  border-radius: 100%;
  margin-right: 12px;
}

.rtl .sidebar .nav .nav-item.sidebar-user-actions .user-details .sidebar-profile-img {
  margin-left: 1rem;
  margin-right: 0;
}

.sidebar .nav .nav-item.sidebar-user-actions .user-details .sidebar-profile-text {
  color: #ffffff;
}

.sidebar .nav .nav-item.sidebar-user-actions .user-details .badge {
  margin-right: 0;
}

.sidebar .nav .nav-item.sidebar-user-actions .sidebar-user-menu {
  padding: 0 2.375rem;
}

.sidebar .nav .nav-item.sidebar-user-actions .sidebar-user-menu .nav-link {
  font-size: 15px;
}

.sidebar .nav .nav-item.sidebar-user-actions .sidebar-user-menu .nav-link i {
  font-size: 18px;
  margin-right: 12px;
}

.rtl .sidebar .nav .nav-item.sidebar-user-actions .sidebar-user-menu .nav-link i {
  margin-right: 0;
  margin-left: 12px;
}

.sidebar .nav:not(.sub-menu)>.nav-item:hover:not(.nav-category):not(.nav-profile)>.nav-link {
  color: #25375b;
}

.sidebar .nav.sub-menu {
  margin-bottom: 5px;
  margin-top: 0;
  list-style: none;
  height: auto;
}

.sidebar .nav.sub-menu .nav-item {
  padding: 0;
}

.sidebar .nav.sub-menu .nav-item .nav-link {
  color: #888;
  padding: 0.75rem 1rem 0.75rem 3rem;
  position: relative;
  font-size: 13px;
  line-height: 1;
  height: auto;
  border-top: 0;
  cursor: pointer;
}

.sidebar .nav.sub-menu .nav-item .nav-link:before {
  content: "\F054";
  font-family: "Material Design Icons";
  display: block;
  position: absolute;
  left: 17px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #a2a2a2;
  font-size: 0.75rem;
}

.sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link:before {
  left: 0;
  color: #ccc;
}

.rtl .sidebar .nav.sub-menu .nav-item .nav-link:before {
  left: auto;
  right: 0;
  content: "\F04D";
}

.sidebar .nav.sub-menu .nav-item .nav-link.active {
  color: #25375b;
  background: transparent;
}

.sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link.active:before {
  color: #ffffff;
}

.sidebar .nav.sub-menu .nav-item .nav-link.active:before {
  color: #25375b;
}

.sidebar .nav.sub-menu .nav-item .nav-link:hover {
  color: #555555;
  background-color: transparent;
}

.sidebar .nav.sub-menu .nav-item:hover {
  background: transparent;
}

.sidebar-light .sidebar {
  background: #fff;
}

.sidebar-light .sidebar .nav .nav-item .nav-link {
  color: #444343;
}

.sidebar-light .sidebar .nav .nav-item .nav-link i {
  color: inherit;
}

.sidebar-light .sidebar .nav .nav-item .nav-link i.menu-icon {
  color: #44ce42;
}

.sidebar-light .sidebar .nav .nav-item .nav-link i.menu-arrow {
  color: #9e9da0;
}

.sidebar-light .sidebar .nav .nav-item .nav-link i.menu-arrow:before {
  color: inherit;
}

.sidebar-light .sidebar .nav .nav-item .nav-link .menu-title {
  color: inherit;
}

.sidebar-light .sidebar .nav .nav-item .nav-link .icon-bg {
  background: rgba(0, 0, 0, 0.12);
}

.sidebar-light .sidebar .nav .nav-item.active {
  background: #fff;
}

.sidebar-light .sidebar .nav .nav-item.active>.nav-link .menu-title {
  color: #444343;
}

.sidebar-light .sidebar .nav .nav-item.active>.nav-link i {
  color: #44ce42;
}

.sidebar-light .sidebar .nav .nav-item:hover {
  background: white;
}

.sidebar-light .sidebar .nav .nav-item.sidebar-actions:hover {
  background: initial;
}

.sidebar-light .sidebar .nav .nav-item.sidebar-actions:hover .nav-link {
  color: initial;
}

.sidebar-light .sidebar .nav:not(.sub-menu)>.nav-item:hover:not(.nav-category):not(.nav-profile)>.nav-link {
  color: #27367f;
}

.sidebar-light .sidebar .nav.sub-menu .nav-item .nav-link {
  color: #444343;
}

.sidebar-light .sidebar .nav.sub-menu .nav-item .nav-link:before {
  color: #a2a2a2;
}

.sidebar-light .sidebar .nav.sub-menu .nav-item .nav-link.active {
  color: #444343;
  background: transparent;
}

.sidebar-light .sidebar .nav.sub-menu .nav-item .nav-link:hover {
  color: black;
}

.sidebar-light .sidebar .nav.sub-menu .nav-item:hover {
  background: transparent;
}

/* sidebar */

/* layout */
/* Layouts */

/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - 64px);
    top: 0;
    bottom: 0;
    overflow: auto;
    left: -258px;
    -webkit-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    z-index: 999;
  }

  .sidebar {
    min-height: 100vw;
  }

  .sidebar-offcanvas.active {
    left: 0;
  }

  .sidebar-offcanvas.active~.main-panel:before {
    background-color: #000000;
    height: 100%;
    width: 100%;
    z-index: 99;
    content: "s";
    position: fixed;
    opacity: 0.3;
  }

  button.navbar-toggler.navbar-toggler-right.open span.mdi.mdi-menu:before {
    content: "\F156";
  }
}

.navbar.fixed-top+.page-body-wrapper {
  padding-top: 64px;
}

@media (min-width: 992px) {
  .sidebar-mini .navbar .navbar-brand-wrapper {
    width: 185px;
  }

  .sidebar-mini .navbar .navbar-menu-wrapper {
    width: calc(100% - 185px);
  }

  .sidebar-mini .sidebar {
    width: 185px;
  }

  .sidebar-mini .sidebar .nav .nav-item {
    padding: 10px 0;
    margin: 0;
  }

  .sidebar-mini .sidebar .nav .nav-item .nav-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    text-align: center;
    position: relative;
    border-bottom: none;
  }

  .sidebar-mini .sidebar .nav .nav-item .nav-link .menu-title {
    display: block;
    margin: auto;
  }

  .sidebar-mini .sidebar .nav .nav-item .nav-link .menu-sub-title {
    margin: auto;
  }

  .sidebar-mini .sidebar .nav .nav-item .nav-link .badge {
    margin-left: 5px;
    display: none;
  }

  .sidebar-mini .sidebar .nav .nav-item .nav-link .icon-bg {
    margin-top: 8px;
  }

  .sidebar-mini .sidebar .nav .nav-item .nav-link .icon-bg i.menu-icon {
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top: 5px;
  }

  .sidebar-mini .sidebar .nav .nav-item .nav-link i.menu-arrow {
    display: inline-block;
    margin-left: 5px;
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .sidebar-mini .sidebar .nav .nav-item .nav-link i.menu-arrow:before {
    content: "\F140";
  }

  .sidebar-mini .sidebar .nav .nav-item .nav-link[aria-expanded="true"] .menu-arrow:before {
    content: "\f143";
  }

  .sidebar-mini .sidebar .nav .nav-item.nav-category {
    text-align: center;
  }

  .sidebar-mini .sidebar .nav .nav-item.sidebar-user-actions .sidebar-profile-text {
    display: none;
  }

  .sidebar-mini .sidebar .nav .nav-item.sidebar-user-actions.hover-open .nav-link .menu-title {
    left: 45px;
  }

  .sidebar-mini .main-panel {
    width: calc(100% - 185px);
  }

  .sidebar-mini:not(.sidebar-icon-only) .sidebar .nav:not(.sub-menu)>.nav-item {
    border-bottom: 1px solid rgba(68, 67, 67, 0.2);
  }

  .sidebar-mini:not(.sidebar-icon-only) .sidebar .nav:not(.sub-menu)>.nav-item .nav-link {
    height: auto;
    padding: 0.8125rem 1rem 0.8125rem 1rem;
  }

  .sidebar-mini:not(.sidebar-icon-only) .sidebar .nav:not(.sub-menu)>.nav-item .nav-link i.menu-icon {
    margin-bottom: 0.5rem;
  }

  .sidebar-mini:not(.sidebar-icon-only) .sidebar .nav.sub-menu {
    padding: 0;
    border-top: none;
  }

  .sidebar-mini:not(.sidebar-icon-only) .sidebar .nav.sub-menu .nav-item {
    padding: 5px 0;
  }

  .sidebar-mini:not(.sidebar-icon-only) .sidebar .nav.sub-menu .nav-item .nav-link {
    padding: 7px 0 7px 25px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: left;
    width: 66%;
  }
}

@media (min-width: 992px) {
  .sidebar-icon-only .navbar .navbar-brand-wrapper {
    width: 70px;
  }

  .sidebar-icon-only .navbar .navbar-brand-wrapper .brand-logo {
    display: none;
  }

  .sidebar-icon-only .navbar .navbar-brand-wrapper .brand-logo-mini {
    display: inline-block;
  }

  /* .sidebar-icon-only .navbar .navbar-menu-wrapper {
    width: calc(100% - 70px);
  } */

  .sidebar-icon-only .sidebar {
    width: 70px;
    padding: 0;
  }

  .sidebar-icon-only .sidebar .nav {
    overflow: visible;
  }

  .sidebar-icon-only .sidebar .nav .nav-item {
    position: relative;
    padding: 0;
  }

  .sidebar-icon-only .sidebar .nav .nav-item .nav-link {
    display: block;
    text-align: center;
  }

  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title,
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .badge,
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-sub-title {
    display: none;
  }

  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
    border-radius: 0 5px 5px 0px;
  }

  .sidebar-icon-only .sidebar .nav .nav-item .nav-link.dropsubmenu .menu-title {
    border-radius: 0 5px 0 0px;
  }

  .rtl.sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
    border-radius: 5px 0 0 5px;
  }

  .sidebar-icon-only .sidebar .nav .nav-item {
    margin: 5px 0;
    min-width: 61px;
  }

  .sidebar-icon-only .sidebar .nav .nav-item .nav-link {
    padding: 13px 10px;
  }

  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .icon-bg {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0px;
  }

  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .icon-bg i {
    margin-left: auto;
    margin-right: auto;
  }

  .sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-icon {
    margin-right: 0;
    margin-left: 0;
  }

  .sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-arrow {
    display: none;
  }

  .sidebar-icon-only .sidebar .nav .nav-item .nav-link[aria-expanded] .menu-title {
    border-radius: 0 5px 0 0px;
  }

  .rtl.sidebar-icon-only .sidebar .nav .nav-item .nav-link[aria-expanded] .menu-title {
    border-radius: 5px 0 0 0;
  }

  .sidebar-icon-only .sidebar .nav .nav-item.nav-profile {
    display: none;
  }

  .sidebar-icon-only .sidebar .nav .nav-item.nav-category {
    display: none;
  }

  .sidebar-icon-only .sidebar .nav .nav-item.nav-doc {
    margin: 0;
  }

  .sidebar-icon-only .sidebar .nav .nav-item.nav-doc i {
    display: block;
  }

  .sidebar-icon-only .sidebar .nav .nav-item .collapse {
    display: none;
  }

  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #21295a;
    color: #fff;
    padding: 0.5rem 1rem;
    left: 70px;
    position: absolute;
    text-align: left;
    top: 0;
    bottom: 0;
    width: 190px;
    z-index: 1;
    line-height: 1.8;
    box-shadow: rgb(15 15 15 / 8%) 0px 4px 12px,
      rgb(15 15 15 / 20%) 0px 9px 24px;
  }

  .sidebar-light.sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
    background: #fff;
  }

  .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
    left: auto;
    right: 70px;
    text-align: left;
  }

  .sidebar-light.sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
    color: #343a40;
  }

  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title:after {
    display: none;
  }

  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
    display: block;
    padding: 0.5rem 0;
    background: #21295a;
    border-radius: 0 0 5px 0;
    position: absolute;
    left: 70px;
    width: 190px;
    box-shadow: rgb(15 15 15 / 8%) 0px 4px 12px,
      rgb(15 15 15 / 20%) 0px 9px 24px;
  }

  .sidebar-light.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
  .sidebar-light.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
    background: #fff;
  }

  .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
  .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
    left: auto;
    right: 70px;
    border-radius: 0 0 0 5px;
  }

  .sidebar-icon-only .sidebar .nav .nav-item.documentation-link {
    margin: 0 !important;
  }

  .sidebar-icon-only .sidebar .nav .nav-item.documentation-link .nav-link {
    background: transparent;
    color: #ffffff;
    border-radius: 0px;
  }

  .sidebar-icon-only .sidebar .nav .nav-item.documentation-link .nav-link .icon-bg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .sidebar-icon-only .sidebar .nav .nav-item.documentation-link .nav-link .menu-title {
    margin: auto;
    background: #fff;
    color: #444343;
    font-weight: 400;
  }

  .sidebar-icon-only .sidebar .nav .nav-item.sidebar-user-actions .user-details {
    display: none;
  }

  .sidebar-icon-only .sidebar .nav .nav-item.sidebar-user-actions .sidebar-user-menu {
    padding: 0 1.5rem;
  }

  .sidebar-icon-only .sidebar .nav.sub-menu {
    padding: 0 0 0 1.5rem;
  }

  .sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link {
    text-align: left;
    padding-left: 20px;
    color: #fff;
  }

  .rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item {
    margin-right: 0;
    margin-left: auto;
  }

  .rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link {
    text-align: left;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
  }

  .sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link:hover {
    color: #7b88d4;
    padding-left: 25px;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
  }

  .sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link:hover:before {
    left: 5px;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
  }

  .rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link:before {
    left: unset;
    right: 0;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
  }

  .sidebar-icon-only .sidebar .sidebar-actions {
    display: none;
  }

  .sidebar-icon-only .main-panel {
    width: calc(100% - 70px);
    margin-left: 70px;
  }
}

@media (min-width: 992px) {
  .sidebar-hidden .sidebar {
    transition: width 0.25s ease;
    -webkit-transition: width 0.25s ease;
    -moz-transition: width 0.25s ease;
    -ms-transition: width 0.25s ease;
    width: 0;
  }

  .sidebar-hidden .main-panel {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .sidebar-absolute .page-body-wrapper {
    position: relative;
  }

  .sidebar-absolute .page-body-wrapper .sidebar {
    -webkit-transition: none;
    transition: none;
  }

  .sidebar-absolute:not(.sidebar-hidden) .sidebar {
    position: absolute;
    height: 100%;
    -webkit-box-shadow: 0 0 3px 1px #a7a3a3;
    /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    /* Firefox 3.5 - 3.6 */
    box-shadow: 0 0 3px 1px #a7a3a3;
    /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  }

  .sidebar-absolute .main-panel {
    width: 100%;
    -webkit-transition: none;
    transition: none;
  }
}

@media (min-width: 992px) {
  .sidebar-fixed .sidebar {
    position: fixed;
    max-height: auto;
  }

  .sidebar-fixed .sidebar .nav {
    max-height: calc(100vh - 64px);
    overflow: auto;
    position: relative;
  }

  .sidebar-fixed .sidebar .nav.sub-menu {
    max-height: none;
  }

  .sidebar-fixed .main-panel {
    margin-left: 258px;
  }

  .sidebar-fixed.sidebar-icon-only .main-panel {
    margin-left: 70px;
  }
}

@media (min-width: 992px) {
  .boxed-layout .container-scroller {
    background: #e5e7f1;
    padding: 0 calc((100% - 1200px) / 2);
  }

  .boxed-layout .navbar.fixed-top {
    margin: auto;
    max-width: 1200px;
  }
}

.rtl {
  direction: rtl;
  text-align: right;
}

.rtl .sidebar .nav {
  padding-right: 0;
}

.rtl.sidebar-icon-only .sidebar .nav.sub-menu {
  padding: 0 1.5rem 0 1.5rem;
}

.rtl .product-chart-wrapper::-webkit-scrollbar,
.rtl .settings-panel .tab-content .tab-pane .scroll-wrapper::-webkit-scrollbar,
.rtl .sidebar-fixed .nav::-webkit-scrollbar,
.rtl .table-responsive::-webkit-scrollbar,
.rtl ul.chats::-webkit-scrollbar {
  width: 0.5em;
}

.rtl .product-chart-wrapper::-webkit-scrollbar-track,
.rtl .settings-panel .tab-content .tab-pane .scroll-wrapper::-webkit-scrollbar-track,
.rtl .sidebar-fixed .nav::-webkit-scrollbar-track,
.rtl .table-responsive::-webkit-scrollbar-track,
.rtl ul.chats::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.rtl .product-chart-wrapper::-webkit-scrollbar-thumb,
.rtl .settings-panel .tab-content .tab-pane .scroll-wrapper::-webkit-scrollbar-thumb,
.rtl .sidebar-fixed .nav::-webkit-scrollbar-thumb,
.rtl .table-responsive::-webkit-scrollbar-thumb,
.rtl ul.chats::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.page-body-wrapper {
  min-height: calc(100vh - 64px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
}

.page-body-wrapper.full-page-wrapper {
  width: 100%;
  min-height: 100vh;
}

.main-panel {
  -webkit-transition: width 0.25s ease, margin 0.25s ease;
  transition: width 0.25s ease, margin 0.25s ease;
  width: calc(100% - 258px);
  min-height: calc(100vh - 64px);
  margin-left: 258px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
}

@media (max-width: 991px) {
  .main-panel {
    margin-left: 0;
    width: 100%;
  }
}

.content-wrapper {
  background: #f4f6fb;
  /* padding: 10px 20px 20px; */
  width: auto;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 10px;
  /* margin-top: 75px; */
}

@media (max-width: 991px) {
  .content-wrapper {
    padding: 2rem 1rem;
  }
}

/* layout */

/* Footer */
footer.footer {
  background: #f0f1f6;
  color: #aab2bd;
  border-top: none;
  padding: 20px 40px;
  transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  font-size: 12px;
  font-family: 'Roboto';
  font-weight: 400;
}

.footer .footer-inner-wraper {
  width: 100%;
  padding-right: 9px;
  padding-left: 9px;
  margin-right: auto;
  margin-left: auto;
}

.footer a {
  color: #a7afb7;
  font-size: inherit;
}

.footer .best-site-txt {
  font-size: 12px;
}

@media (max-width: 991px) {
  .footer {
    margin-left: 0;
    width: 100%;
  }
}

@media (max-width: 320px) {
  .navbar .navbar-brand-wrapper {
    width: 125px;
    height: 100%;
  }

  .navbar .navbar-brand-wrapper .navbar-brand img {
    /* height: 45px; */
  }

  .navbar .navbar-menu-wrapper {
    padding-left: 0px;
  }
}

.fullWidth {
  width: 100% !important;
}

@media (min-width: 320px) and (max-width: 767px) {
  .content-wrapper {
    padding: 1rem 0rem;
  }
}